import React, { FunctionComponent, useState, useRef, useContext } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { Icon } from '../Icons';
import { useUrl } from 'hooks/useUrl';
import { useAppSelector } from 'hooks/redux';

interface ShareProps {
  label: string;
  title: string;
  showLabel?: boolean;
}

export const Share: FunctionComponent<ShareProps> = (props) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const {
    isiOSCreatorApp,
    isAndroidCreatorApp,
    isListenerAppWebview,
    isEmbed
  } = useContext(UserAgentContext);
  const inputCopyRef = useRef<HTMLInputElement>(null);
  const url = useUrl();
  const removeEmbedBranding: boolean = useAppSelector(
    (state) => state.channel?.channelDetails?.removeEmbedBranding || false
  );

  const removeBranding: boolean = isEmbed && removeEmbedBranding;

  const openShare = () => {
    if (isEmbed) {
      setShowShareModal(true);
    } else if (navigator.share) {
      navigator
        .share({
          title: props.title,
          url: url
        })
        .then(() => console.log('Successful share via navigator'))
        .catch((error: Error) => console.log('Error sharing', error));
    } else if (isiOSCreatorApp || isAndroidCreatorApp || isListenerAppWebview) {
      window.location.href = `share://${props.label}?url=${url}&title=${props.title}`;
    } else {
      setShowShareModal(true);
    }
  };

  const copyInput = (e: React.MouseEvent<HTMLElement>) => {
    const button = e.currentTarget;
    inputCopyRef.current && inputCopyRef.current.select();
    button.classList.add('copied');
    document.execCommand('copy');
    setTimeout(() => {
      button.classList.remove('copied');
    }, 3000);
  };

  const closeShare = () => {
    setShowShareModal(false);
  };

  if (removeBranding) {
    return null;
  }

  return (
    <>
      <IconButton
        label={`Share ${props.label}`}
        onClick={openShare}
        icon={'share'}
        showLabel={props.showLabel}
      />
      <ModalWrapper
        isOpen={showShareModal}
        title={`Share ${props.label}`}
        onRequestClose={closeShare}
      >
        <div className="clipboard">
          <input
            ref={inputCopyRef}
            className="clipboard__input form__input"
            type="text"
            value={url}
            readOnly
          />
          <button
            onClick={(e) => copyInput(e)}
            className="clipboard__button button button--icon"
            type="button"
          >
            <span className="visibility-hidden">Copy {props.label} url</span>
            <Icon
              icon={'copy'}
              height={16}
              hidden={true}
              label={'Copy event url'}
              width={16}
            />
          </button>
        </div>
      </ModalWrapper>
    </>
  );
};
