import React, { FunctionComponent, useContext } from 'react';
import { IconButton } from 'components/Buttons/IconButton';
import { useIsBroadcaster } from 'features/current-user/hooks';
import { useIsFullScreenView } from 'features/theme/themeHooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useMediaQuery } from 'react-responsive';

export const CreatorSignUpButton: FunctionComponent = () => {
  const { isEmbed } = useContext(UserAgentContext);
  const hideOnSmallScreensOrEmbed =
    useMediaQuery({ query: '(max-width: 900px)' }) || isEmbed;
  const isFullScreenView = useIsFullScreenView();
  const isBroadcaster = useIsBroadcaster();

  if (hideOnSmallScreensOrEmbed) {
    return null;
  }

  if (isBroadcaster) {
    return null;
  }

  return (
    <IconButton
      showLabel={true}
      icon={'mixlr'}
      label={'Go Live'}
      href={`${process.env.REACT_APP_CREATOR_ENDPOINT}`}
      external={true}
      isTransparent={isFullScreenView}
      isLight={!isFullScreenView}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};
