import { sendChatMessage } from 'features/chat/chatSlice';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import styles from './styles.module.scss';
import { useCurrentUser } from 'features/current-user/hooks';
import { EmojiPicker } from 'components/EmojiPicker';
import { IEmojiData } from 'emoji-picker-react';
import GiphyPicker from 'components/GiphyPicker';
import { IGif } from '@giphy/js-types';
import { HeartButton } from 'components/Hearts/HeartButton';

interface ChatInputProps {
  channelId: string | null;
}

export const ChatInput: FunctionComponent<ChatInputProps> = (props) => {
  const chatVisible = useSelector((state: RootState) => state.chat.isVisible);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const socketId = useSelector((state: RootState) => state.hub.socketId);
  const [chatInput, setChatInput] = useState('');
  const currentUser = useCurrentUser();
  const chatLabel = currentUser
    ? `Chat as ${currentUser.username}...`
    : 'Chat now...';

  const submitForm = () => {
    if (!props.channelId) {
      return;
    }

    dispatch(sendChatMessage(props.channelId, chatInput, socketId));
    setChatInput('');
    if (textareaRef.current) {
      textareaRef.current.blur();
      textareaRef.current.focus();
    }
  };

  const sendMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitForm();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitForm();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatInput(e.target.value);
  };

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setChatInput(chatInput + emojiObject.emoji);
  };

  const onGifClick = (gif: IGif) => {
    if (props.channelId) {
      dispatch(
        sendChatMessage(
          props.channelId,
          gif.images.downsized_medium.url,
          socketId
        )
      );
    }
  };

  useEffect(() => {
    if (textareaRef.current !== null) {
      textareaRef.current.style.height = 'auto';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [chatInput]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '46px';
    }
  }, [chatVisible]);

  return (
    <div className={styles['chat__input']}>
      <form
        onSubmit={sendMessage}
        className={styles['chat__form']}
        onKeyPress={onKeyPress}
      >
        <label htmlFor="chat-input" className="visually-hidden">
          {chatLabel}
        </label>
        <HeartButton />
        <GiphyPicker onGifClick={onGifClick} />
        <textarea
          id="chat-input"
          ref={textareaRef}
          className={styles['chat__textarea']}
          dir="auto"
          placeholder={chatLabel}
          rows={1}
          data-min-rows={1}
          data-max-rows={3}
          value={chatInput}
          onChange={handleOnChange}
        ></textarea>
        <EmojiPicker
          onEmojiClick={onEmojiClick}
          searchPlaceholder="Search emojis..."
        />
        <button className={styles['chat__submit']} type="submit">
          Post
        </button>
      </form>
    </div>
  );
};
