import React, { FunctionComponent, useContext } from 'react';
import { useIsBroadcaster } from 'features/current-user/hooks';
import { HeartCount } from 'components/Hearts/HeartCount';
import { useCurrentEvent } from 'features/events/eventHooks';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { HeartButton } from './HeartButton';
import { Icon } from 'components/Icons';

export const HeartButtonWrapper: FunctionComponent = () => {
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);
  const currentBroadcastUID = currentBroadcast?.uid;
  const isBroadcaster = useIsBroadcaster();
  const event = useCurrentEvent();
  const { isEmbed } = useContext(UserAgentContext);

  const heartingButtonDisabled = !currentBroadcastUID || isBroadcaster;

  const renderIconOrButton = () => {
    if (heartingButtonDisabled) {
      return (
        <Icon hidden={true} icon={'heart'} label={''} height={18} width={18} />
      );
    }
    return <HeartButton externalLink={isEmbed} />;
  };

  if (event && !event.heartingEnabled) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center tw-rounded-full tw-h-[36px] tw-pl-4 tw-space-x-3 tw-bg-black/30 tw-text-white tw-backdrop-blur-sm">
      {renderIconOrButton()}
      <HeartCount onlyCount={true} />
    </div>
  );
};
