import { sendBroadcastHeart } from 'features/broadcast/thunks';
import { useIsBroadcaster, useCurrentUser } from 'features/current-user/hooks';
import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { useCurrentEvent } from 'features/events/eventHooks';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import { UserAgentContext } from 'features/user-agent/userAgent';

interface HeartButtonProps {
  externalLink?: boolean;
}

export const HeartButton: FunctionComponent<HeartButtonProps> = (props) => {
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);
  const currentBroadcastUID = currentBroadcast?.uid;

  const isHeartLoading = useSelector(
    (state: RootState) => state.broadcasts.heartLoading
  );
  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();
  const dispatch = useDispatch();
  const event = useCurrentEvent();
  const { isListenerAppWebview } = useContext(UserAgentContext);

  const heartingButtonDisabled = !currentBroadcastUID || isBroadcaster;

  const heartBroadcast = () => {
    if (!currentBroadcastUID) {
      return;
    }
    if (props.externalLink) {
      window.open(window.location.href, '_blank');
      return;
    }
    if (currentUser) {
      dispatch(sendBroadcastHeart(currentBroadcastUID));
    } else {
      if (isListenerAppWebview) {
        window.location.href = `mixlr://auth?url=${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`;
      } else {
        dispatch(showAuthenticationEmailPromptForm(`To send a ❤️`));
      }
    }
  };

  if (event && !event.heartingEnabled) {
    return null;
  }

  if (heartingButtonDisabled) {
    return null;
  }

  return (
    <button
      className="tw-border-0 tw-m-0 tw-p-0 tw-h-[18px] tw-w-[18px] tw-flex-none tw-cursor-pointer tw-bg-transparent"
      onClick={heartBroadcast}
      disabled={isHeartLoading}
    >
      <div
        className={`tw-bg-gradient-to-t tw-from-mixlr-red tw-from-50% tw-via-red-200 tw-via-50% tw-to-red-200 tw-bg-size-200 ${
          isHeartLoading ? 'tw-animate-fillHeart' : 'tw-bg-pos-100'
        } tw-h-[18px] tw-w-[18px] tw-clip-path-url-[#heartPath]`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          height="18px"
          width="18px"
        >
          <clipPath id="heartPath" clipPathUnits="objectBoundingBox">
            <path d="M0.0805474 0.143047C-0.0268491 0.250444 -0.0268491 0.424568 0.0805474 0.531964L0.5 0.951417L0.919453 0.531964C1.02685 0.424568 1.02685 0.250444 0.919453 0.143047L0.91167 0.135559C0.803802 0.0357118 0.635375 0.0382079 0.530536 0.143047L0.5 0.173583L0.469464 0.143047C0.362068 0.0356509 0.187944 0.0356509 0.0805474 0.143047Z" />
          </clipPath>
        </svg>
      </div>
      <span className="tw-sr-only">Heart this event</span>
    </button>
  );
};
