import React, { FunctionComponent, ReactNode } from 'react';
import { FullScreenTitle } from './FullScreenTitle';

interface FullScreenDetailsProps {
  title?: string | null;
  image?: ReactNode;
  meta?: ReactNode;
  controls?: ReactNode;
}

export const FullScreenDetails: FunctionComponent<FullScreenDetailsProps> = (
  props
) => {
  return (
    <div className="tw-w-full sm:tw-text-center tw-space-y-4 md:tw-space-y-8 [.ios_&]:tw-space-y-4 tw-text-white tw-px-8 mobile-landscape:tw-max-w-[356px] mobile-landscape:tw-flex mobile-landscape:tw-gap-8 mobile-landscape:tw-items-center [.embed_&]:tw-text-left [.embed_&]:tw-px-2">
      {props.image && (
        <div className="tw-max-w-[180px] sm:tw-max-w-[164px] md:tw-max-w-[248px] xl:tw-max-w-[356px] tw-w-full tw-mx-auto mobile-landscape:tw-max-w-[100px] [.ios_&]:mobile-portrait:tw-max-w-full">
          {props.image}
        </div>
      )}
      <div className="tw-flex-1">
        {props.title && <FullScreenTitle title={props.title} />}
        {props.meta && (
          <div className="tw-flex tw-space-x-8 tw-justify-center tw-items-center tw-mb-2 md:tw-mb-4 [.embed_&]:tw-justify-start [.embed_&]:tw-mb-0 [.embed_&]:tw-text-xl">
            {props.meta}
          </div>
        )}
        {props.controls && props.controls}
      </div>
    </div>
  );
};
